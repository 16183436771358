import FingerprintJS, { type GetResult } from '@fingerprintjs/fingerprintjs';

export default function useFingerprint () {

  const getVisitorId = async (): Promise<GetResult['visitorId']> => {
    const { load } = FingerprintJS;

    const agent = await load();

    const { visitorId } = await agent.get();

    return visitorId;
  };

  return {
    getVisitorId
  };
}

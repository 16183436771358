import { RouteRecordRaw } from 'vue-router';
import { ROUTE_NAMES } from '@/shared/utils/constants/router.ts';

export const directoriesRoutes = [
  {
    path: '/directories',
    children: [
      {
        path: '',
        name: ROUTE_NAMES.directories.directories,
        component: () => import('../../pages/Directories.vue')
      },
      {
        path: 'exchange-rates',
        name: ROUTE_NAMES.directories.exchangeRates,
        component: () =>
          import('../../pages/exchange-rates/ExchangeRatesPage.vue')
      },
      {
        path: 'list-banks',
        name: ROUTE_NAMES.directories.listBanks,
        component: () => import('../../pages/list-banks/ListBanksPage.vue')
      },
      {
        path: 'destination-codes',
        name: ROUTE_NAMES.directories.destinationCodes,
        component: () =>
          import('../../pages/destination-codes/DestinationCodesPage.vue')
      }
    ],
    props: true
  }
] as RouteRecordRaw[];

import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { defineStore } from 'pinia';
import {
  ApiAuth,
  ApiBusiness,
  ApiCommon,
  ApiDigitalSignature
} from '@/shared/api';
import {
  ApprovalLevels,
  IBusinessBranch,
  IBusinessDay,
  IBusinessSettings,
  ICertificate,
  IResponseData,
  ModuleKeys, OperationDayStates, ScreenJumpTypes,
  SettingsModule,
  UserSettings,
  UserTypes
} from '@/shared/types';
import {
  EConfirmType,
  IAuthConfirm,
  IFetchConfirmTypesPayload,
  IFetchConfirmTypesResponse,
  ILogin,
  IRefreshToken,
  LoginForm
} from '@/shared/types/auth.types';
import authBrowserStore from '@/shared/utils/auth-browser-store';
import { getTimeRemaining, parseDateString } from '@/shared/utils/functions/date';
import { firebaseLogEvent } from '@/shared/utils/functions/firebase';
import { usePermissionStore } from './permissionStore';
import useDepositsTest from '@/sections/deposits/shared/composables/useDepositsTest';

interface IUser {
  userName: string;
  userId: number;
}
interface IAuthLocalstorage {
  selectedBranch: string;
  user: IUser;
}

// https://confluence.paymart.uz/pages/viewpage.action?pageId=1282324
// const BUSINESS_DAY_STATES = {
//   inProcessOfOpening: '011',
//   inProcessOfClosing: '101',
//   opened: '100',
//   closed: '010'
// };

export const useAuthStore = defineStore(
  'auth',
  () => {
    const router = useRouter();
    const permissionStore = usePermissionStore();
    const depositsTest = useDepositsTest();

    let authLocalStorage: IAuthLocalstorage | null = null;

    const auth = localStorage.getItem('auth');
    authLocalStorage = JSON.parse(auth || '{}');

    const accessToken = ref<string>(authBrowserStore.accessToken);
    const refreshToken = ref<string>(authBrowserStore.refreshToken);
    const user = ref<IUser | null>(authLocalStorage?.user || null);
    const businessBranchesList = ref<IBusinessBranch[]>([]);
    const businessSettings = ref<SettingsModule[]>([]);
    const businessSettingsModule = ref<SettingsModule | null>(null);

    const selectedBranch = ref<string>(authLocalStorage?.selectedBranch || '');
    const businessDay = ref<(IBusinessDay & { curDateParsed: number }) | null>(
      null
    );
    const isSignOutManualy = ref(false);
    const tokenCertificates = ref<ICertificate[] | null>(null);
    const epassSignature = ref<string | null>(null);

    const esbModules = ref<IBusinessSettings['esbModules']>([]);
    const confirmTypes = ref<EConfirmType[]>([]);
    const confirmCertificates = ref<string[]>([]);

    const isOperationDayOpen = computed(() => {
      return businessDay.value?.b2OperDayState == OperationDayStates.Open;
    });

    const currentOperationDate = computed<number>(() => {
      const res = isOperationDayOpen.value
        ? businessDay.value?.curDate
        : businessDay.value?.nextDate;

      if (!res) return new Date().setHours(0, 0, 0, 0);

      return Date.parse(parseDateString(res) as string);
    });

    const isAuth = computed(
      () => !isSignOutManualy.value && !!accessToken.value
    );

    const businessBranchesOption = computed(() => {
      return businessBranchesList.value?.map((item: IBusinessBranch) => {
        return {
          ...item,
          value: `${item.branch}-${item.businessCode}`,
          label: `${item.branch}-${item.name}`,
          key: `${item.branch}-${item.name}`
        };
      });
    });

    const selectedBranchInfo = computed(() => {
      if (selectedBranch.value.startsWith(UserTypes.agent)) {
        const formattedSelectedBranch = selectedBranch.value.replace(UserTypes.agent, '');
        return businessBranchesList.value.find(
          (item: IBusinessBranch) =>
            String(item.partnerId) === formattedSelectedBranch
        );
      } else {
        const formattedSelectedBranch = selectedBranch.value.replace(UserTypes.business, '');
        return businessBranchesList.value?.find(
          (item: IBusinessBranch) =>
            `${item.branch}-${item.businessCode}` === formattedSelectedBranch
        );
      }
    });

    const eSign = computed(() => {
      if (tokenCertificates.value && epassSignature.value) {
        const validCertificate = tokenCertificates.value.find(
          (certificate) =>
            certificate.serialnumber === epassSignature.value?.toUpperCase()
        );

        if (validCertificate) {
          return validCertificate.serialnumber;
        }

        return null;
      }

      return null;
    });

    const hasAccessToApprove = computed<boolean>(() =>
      !!businessSettingsModule.value?.usersForApproval?.some((setting: UserSettings) => setting.userId === user.value?.userId));

    const hasAccessToSign = computed<boolean>(() =>
      !!businessSettingsModule.value?.usersForSigning?.some((setting: UserSettings) => setting.userId === user.value?.userId));

    const hasMultiLevelApproval = computed<boolean>(() =>
      !!businessSettingsModule.value?.isMultiLevelApproval);

    const maxApprovalLevel = computed<ApprovalLevels>(() =>
      businessSettingsModule.value?.maxApprovalLevel || ApprovalLevels.LEVEL_0);

    const isAgentAvailable = computed<boolean>(
      () => businessBranchesList.value.some(branch => branch.partnerId)
    );

    const isAgent = computed<boolean>(
      () => selectedBranch.value.startsWith(UserTypes.agent)
    );

    const isBusinessAvailable = computed<boolean>(
      () => businessBranchesList.value.some(branch => branch.businessCode)
    );

    async function fetchCertificates() {
      const { certInfos, error } =
        await ApiDigitalSignature.fetchCertificates();
      const certificates = certInfos?.filter((certificate) => !!certificate);

      if (!error && certificates.length) {
        tokenCertificates.value = certificates;
      } else {
        tokenCertificates.value = null;
      }

      return certificates;
    }

    function clearToken() {
      tokenCertificates.value = null;
    }

    async function fetchEpassSignature(): Promise<void> {
      const { item } = await ApiDigitalSignature.fetchEpassSignature();

      epassSignature.value = item?.serialNumber ?? null;
    }

    async function signIn(formData: LoginForm) {
      const result = await ApiAuth.signIn(formData);
      const { item, error } = result;

      if (!error) {
        updateAccessToken({
          accessToken: item?.accessToken || '',
          refreshToken: item?.refreshToken || ''
        });

        updateUser({
          userName: item.userName,
          userId: item.userId
        });
      }

      return result;
    }

    function updateUser(userData: IUser) {
      user.value = userData;
    }

    async function confirmAuth(
      reqData: IAuthConfirm,
      confirmType: EConfirmType
    ) {
      let request: (req: IAuthConfirm) => Promise<IResponseData<IRefreshToken | ILogin>> = ApiAuth.confirmOtpCode;

      if (confirmType === EConfirmType.byEmail) {
        request = ApiAuth.confirmByEmail;
      } else if (confirmType === EConfirmType.bySignature) {
        request = ApiAuth.confirmBySignature;
      }

      const result = await request(reqData);

      if (result.error) return result;

      updateAccessToken({
        accessToken: result.item?.accessToken || '',
        refreshToken: result.item?.refreshToken || ''
      });

      return result;
    }

    async function confirmBySms(reqData: IAuthConfirm) {
      return await confirmAuth(reqData, EConfirmType.bySms);
    }

    async function confirmBySignature(reqData: IAuthConfirm) {
      return await confirmAuth(reqData, EConfirmType.bySignature);
    }

    async function confirmByEmail(reqData: IAuthConfirm) {
      return await confirmAuth(reqData, EConfirmType.byEmail);
    }

    async function tryToRefreshToken() {
      const { item, error } = await ApiAuth.refreshToken({
        accessToken: accessToken.value || '',
        refreshToken: refreshToken.value || ''
      });

      !error && updateAccessToken(item);

      return !error;
    }

    async function signOut(clickedManualy = false) {
      isSignOutManualy.value = true;
      const skipRefreshToken = !clickedManualy;

      resetBrowserAccessRefreshToken();

      accessToken.value &&
        (await ApiAuth.signOut(accessToken.value, skipRefreshToken));

      resetAccessRefreshToken();

      firebaseLogEvent({
        eventName: 'logout'
      });

      businessBranchesList.value = [];
      selectedBranch.value = '';
      user.value = null;
      router.replace('/');
      isSignOutManualy.value = false;
    }

    const onFetchBusinessDay = async () => {
      const { item } = await ApiCommon.fetchBusinessDays();

      businessDay.value = {
        ...item,
        curDateParsed: Date.parse(parseDateString(item?.curDate) as string)
      };
    };

    const onFetchBusinessList = async () => {
      if (businessBranchesList.value?.length) return;
      const { items } = await ApiBusiness.onFetchBusinessList();

      const firstBranch = items?.[0] || null;
      if (!firstBranch) return;

      businessBranchesList.value = items;

      if (!selectedBranchInfo.value) {
        const userType = firstBranch.partnerId ? UserTypes.agent : UserTypes.business;

        if (userType === UserTypes.business) {
          selectedBranch.value = `${userType}${firstBranch?.branch}-${firstBranch?.businessCode}`;
        } else {
          selectedBranch.value = `${userType}${firstBranch?.partnerId}`;
        }

        firebaseLogEvent({
          eventName: 'authorization_success'
        });
      }
    };

    const fetchBusinessSettings = async () => {
      const { item, error } = await ApiBusiness.fetchBusinessSettings();

      if (!error) {
        businessSettings.value = item.approvalSettings.modules;
        businessSettingsModule.value = item.approvalSettings.modules.find((business: SettingsModule) => business.moduleKey === ModuleKeys.DEFAULT)!;
        esbModules.value = item.esbModules;
      }
    };

    const goToForbiddenPage = () => router.push({ name: 'forbidden' });

    async function onHandleChangeBranch(newSelectedBranch: string) {
      const oldBusinessId = String(selectedBranch.value.replace('-', '_'));

      await router.push('/');
      selectedBranch.value = newSelectedBranch;

      firebaseLogEvent({
        eventName: 'main_screen_jump',
        event_params: {
          profile: selectedBranchInfo.value?.branch ? ScreenJumpTypes.business : ScreenJumpTypes.partner
        }
      });

      firebaseLogEvent({
        eventName: 'change_business_success',
        event_params: {
          old_business_id: oldBusinessId,
          new_business_id: String(newSelectedBranch.replace('-', '_'))
        }
      });

      window.location.reload();
    }

    function updateAccessToken(item: IRefreshToken | null) {
      if (!item) return;

      authBrowserStore.accessToken = accessToken.value = item.accessToken;
      authBrowserStore.refreshToken = refreshToken.value = item.refreshToken;
    }

    function resetBrowserAccessRefreshToken() {
      authBrowserStore.accessToken = '';
      authBrowserStore.refreshToken = '';
    }

    function resetAccessRefreshToken() {
      accessToken.value = '';
      refreshToken.value = '';
    }

    function resetAllTokens() {
      resetBrowserAccessRefreshToken();
      resetAccessRefreshToken();
    }

    function initEndOfDayCheckTimer() {
      const { milliseconds } = getTimeRemaining();

      setTimeout(resetAllTokens, milliseconds);
    }

    async function init() {
      await onFetchBusinessList();
      if (selectedBranchInfo.value?.partnerId) {
        await permissionStore.fetchAgentActions();
      } else {
        await Promise.all([
          onFetchBusinessDay(),
          permissionStore.fetchActions(),
          fetchBusinessSettings(),
          fetchEpassSignature()
        ]);

        initEndOfDayCheckTimer();

        // TODO После полноценного релиза депозитов удалить
        if (selectedBranchInfo.value) {
          depositsTest.replaceDepositRoutes(selectedBranchInfo.value.businessCode!);
        }
      }
    }

    async function fetchConfirmTypes(formData: IFetchConfirmTypesPayload):
      Promise<{item: IFetchConfirmTypesResponse, error: IResponseData['error']}> {
      const { item, error } = await ApiAuth.fetchConfirmTypes(formData);

      if (item) {
        confirmCertificates.value = item.confirmCertificates;
        confirmTypes.value = item.confirmTypes;
      }

      return { item, error };
    }

    watch(
      () => selectedBranchInfo.value,
      (info) => {
        if (info && !tokenCertificates.value) {
          fetchCertificates();
        }
      }
    );

    return {
      esbModules,
      accessToken,
      refreshToken,
      businessBranchesList,
      selectedBranch,
      businessBranchesOption,
      selectedBranchInfo,
      businessSettings,
      businessDay,
      isAgentAvailable,
      isBusinessAvailable,
      tryToRefreshToken,
      updateAccessToken,
      onHandleChangeBranch,
      init,
      user,
      signIn,
      signOut,
      confirmBySms,
      confirmBySignature,
      eSign,
      onFetchBusinessDay,
      goToForbiddenPage,
      isAuth,
      tokenCertificates,
      fetchCertificates,
      updateUser,
      isOperationDayOpen,
      currentOperationDate,
      hasAccessToApprove,
      hasAccessToSign,
      hasMultiLevelApproval,
      maxApprovalLevel,
      confirmByEmail,
      isAgent,
      fetchEpassSignature,
      clearToken,
      fetchConfirmTypes,
      confirmCertificates,
      confirmTypes
    };
  },
  {
    persist: {
      key: 'auth',
      storage: window.localStorage,
      paths: [ 'selectedBranch', 'user', 'confirmCertificates', 'confirmTypes' ]
    }
  }
);

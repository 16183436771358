import api from '@/shared/plugins/axios';
import {
  ApiVersions,
  IBusinessBranch,
  IBusinessSettings,
  ICardRequisites,
  IPaymentCard,
  IResponseData
} from '@/shared/types';
import { mapClientCardsResponse } from '@/shared/utils/mapper/business-api';
import { PaymentCardsParams, PaymentCardStatuses } from '../types/business.types';
import { CurrencyAlphaCodeNumber } from '../types/currency.types';

export const fetchClientCards = async (params?: PaymentCardsParams): Promise<
  IResponseData<IPaymentCard>
> => {
  const { data } = await api.get(':baseUrl/paymentCards', {
    params,
    apiVersion: ApiVersions.v3
  });

  return mapClientCardsResponse(data);
};

export const fetchClientCardsForStatements = async ({ currencyIds }: { currencyIds?: CurrencyAlphaCodeNumber[] } = {}): Promise<
  IResponseData<IPaymentCard>
> => {
  return fetchClientCards({
    states: [
      PaymentCardStatuses.Active,
      PaymentCardStatuses.Blocked,
      PaymentCardStatuses.Inactive
    ],
    currencyIds
  });
};

export const fetchCardRequisites = async (
  cardNumber: string
): Promise<IResponseData<ICardRequisites>> => {
  const { data } = await api.get(`/api/cards/${cardNumber}/requisites`);
  return data;
};

export const onFetchBusinessList = async (): Promise<
  IResponseData<IBusinessBranch>
> => {
  const { data } = await api.get('/api/business/list', {
    apiVersion: ApiVersions.v3
  });

  return data;
};

export const fetchBusinessDetailInfo = async (): Promise<
  IResponseData<IBusinessBranch>
> => {
  const { data } = await api.get(':baseUrl/detailed-info');
  return data;
};

export const fetchBusinessSettings = async (): Promise<
  IResponseData<IBusinessSettings>
> => {
  const { data } = await api.get(':baseUrl/settings');
  return data;
};

import { RouteRecordRaw } from 'vue-router';
import { ROUTE_NAMES } from '@/shared/utils/constants/router.ts';

export default [
  {
    path: '/settings',
    children: [
      {
        path: '',
        name: ROUTE_NAMES.settings.default,
        component: () => import('../../pages/SettingsPage.vue')
      },
      {
        path: '/settings/change-password',
        name: ROUTE_NAMES.settings.changePassword,
        component: () =>
          import('../../pages/change-password/ChangePasswordPage.vue')
      },
      {
        path: '/settings/change-phone-number',
        name: ROUTE_NAMES.settings.changePhoneNumber,
        component: () =>
          import('../../pages/change-phone/ChangePhonePage.vue')
      },
      {
        path: '/settings/change-email',
        name: ROUTE_NAMES.settings.changeEmail,
        component: () => import('../../pages/change-email/ChangeEmailPage.vue')
      }
    ]
  }
] as RouteRecordRaw[];

import useFingerprint from '../composables/useFingerPrint';
import api from '../plugins/axios';
import { ApiVersions, IResponseData } from '../types';
import {
  ApplicationId,
  BankBranchesRequest,
  BankMfo,
  BusinessDays,
  City,
  ConfirmToken,
  Director,
  ExpectedRevenuePerYear,
  LeaveRequestForConsultationRequest,
  OnboardingApplicationItem,
  OnboardingFinalInfoResponse,
  OnboardingRate,
  OnboardingRegistrationRequestData,
  OnboardingRole,
  OnboardingSendCompanyInfoFormRequest,
  OnboardingSendEmplyeeInfoFormRequest,
  OnboardingSendMeetInfoFormRequest,
  OnboardingShortInfo,
  OnboardingSignIn,
  OnboardingSignInRequestData,
  OnboardingSignUp,
  OnboardingStatus,
  RefreshToken,
  ResendSMS,
  ResendSMSResponse,
  ValidateAuthLink
} from '../types/digital-onboarding.types';

const { getVisitorId } = useFingerprint();

// Вход в систему по логину и паролю
export const signIn = async (
  requestData: OnboardingSignInRequestData
): Promise<IResponseData<OnboardingSignIn>> => {
  const { data } = await api.post(
    '/api/onboarding/auth',
    requestData,
    {
      optionalAuth: true
    }
  );

  return data;
};

// Обновление токена
export const refreshToken = async (
  requestData: RefreshToken
): Promise<IResponseData<RefreshToken>> => {
  const { data } = await api.post(
    '/api/onboarding/refresh',
    requestData,
    {
      optionalAuth: true,
      logoutOnError: true,
      retryCount: 3,
      hideErrorMessage: true
    }
  );

  return data;
};

// Подтвердить
export const confirmToken = async (
  requestData: ConfirmToken
): Promise<IResponseData<RefreshToken>> => {
  const { data } = await api.put(
    '/api/onboarding/confirm',
    requestData,
    {
      optionalAuth: true
    }
  );

  return data;
};

// Переотправка СМС для входа в систему онбординг
export const resendSmsCode = async (
  requestData: ResendSMS
): Promise<IResponseData<ResendSMSResponse>> => {
  const { data } = await api.put(
    '/api/onboarding/resend',
    requestData,
    {
      optionalAuth: true
    }
  );

  return data;
};

// Регистрация
export const signUp = async (
  requestData: OnboardingRegistrationRequestData
): Promise<IResponseData<OnboardingSignUp>> => {

  const { data } = await api.post(
    '/api/onboarding/registration',
    requestData,
    {
      optionalAuth: true
    }
  );

  return data;
};

// Регистрация конфирм
// TODO: deprecated
export const signUpConfirm = async (
  requestData: ConfirmToken
): Promise<IResponseData<RefreshToken>> => {
  const { data } = await api.put(
    '/api/onboarding/registration/confirm',
    requestData,
    {
      optionalAuth: true
    }
  );

  return data;
};

// Регистрация конфирм c FingerPrint
export const signUpConfirmFingerPrint = async (
  requestData?: ConfirmToken
): Promise<IResponseData<RefreshToken>> => {
  const visitorId = await getVisitorId();

  const { data } = await api.put(
    '/api/onboarding/registration/confirm',
    requestData,
    {
      params: { fingerPrint: visitorId },
      optionalAuth: true,
      apiVersion: ApiVersions.v3
    }
  );

  return data;
};

// Завершение регистрации
export const finalizeRegistration = async (): Promise<IResponseData<OnboardingStatus>> => {
  const visitorId = await getVisitorId();

  const { data } = await api.get(
    '/api/onboarding/registration/finalize/sse',
    {
      params: { fingerPrint: visitorId },
      optionalAuth: true
    }
  );

  return data;
};

// Выход
export const logout = async (): Promise<IResponseData<string>> => {
  const { data } = await api.get(
    '/api/onboarding/logout'
  );

  return data;
};

// Получение статуса по заявке
export const fetchDOBStatusById = async (
  id: ApplicationId
): Promise<IResponseData<OnboardingStatus>> => {
  const { data } = await api.get(
    `/api/onboarding/status/${id}`
  );

  return data;
};

// Получение списка заявок
export const fetchDOBApplicationList = async (): Promise<IResponseData<OnboardingApplicationItem>> => {
  const { data } = await api.get(
    '/api/onboarding/application-list'
  );

  return data;
};

// Получение информации из МИН ЮСТ
export const fetchDOBShortInfoById = async (
  id: ApplicationId
): Promise<IResponseData<OnboardingShortInfo>> => {
  const { data } = await api.get(
    `/api/onboarding/short-info/${id}`
  );

  return data;
};

// Отправка формы данных о компании
export const sendDOBCompanyInformationForm = async (
  id: ApplicationId,
  requestData: OnboardingSendCompanyInfoFormRequest
): Promise<IResponseData<boolean>> => {
  const { data } = await api.post(
    `/api/onboarding/company/${id}`,
    requestData
  );

  return data;
};

// Отправка формы данных о сотрудниках
export const sendDOBEmployeeInformationForm = async (
  id: ApplicationId,
  requestData: OnboardingSendEmplyeeInfoFormRequest[]
): Promise<IResponseData<boolean>> => {
  const { data } = await api.post(
    `/api/onboarding/employee/${id}`,
    requestData
  );

  return data;
};

// Отправка формы данных о встречи
export const sendDOBMeetInformationForm = async (
  id: ApplicationId,
  requestData: OnboardingSendMeetInfoFormRequest
): Promise<IResponseData<boolean>> => {
  const { data } = await api.post(
    `/api/onboarding/meet/${id}`,
    requestData
  );

  return data;
};

// Полчение итоговой информации о заявке о открытие счета
export const fetchDOBFinalInformation = async (
  id: ApplicationId
): Promise<IResponseData<OnboardingFinalInfoResponse>> => {
  const { data } = await api.get(
    `/api/onboarding/final-info/${id}`
  );

  return data;
};

// Оставить заявку на консультацию
export const leaveDOBRequestForConsultation = async (
  requestData: LeaveRequestForConsultationRequest
): Promise<IResponseData<boolean>> => {
  const { data } = await api.post(
    '/api/onboarding/counseling',
    requestData,
    {
      optionalAuth: true,
      apiVersion: ApiVersions.v1
    }
  );

  return data;
};

// Получение справочника предполагаемой выручки в год
export const fetchDOBExpectedRevenuePerYear = async (): Promise<IResponseData<ExpectedRevenuePerYear>> => {
  const { data } = await api.get(
    '/api/onboarding/expected-revenue'
  );

  return data;
};

// Получение справочника ролей
export const fetchDOBRoleList = async (): Promise<IResponseData<OnboardingRole>> => {
  const { data } = await api.get(
    '/api/onboarding/role-list'
  );

  return data;
};

// Получение справочника тарифов
export const fetchDOBRateList = async (): Promise<IResponseData<OnboardingRate>> => {
  const { data } = await api.get(
    '/api/onboarding/rate-list',
    {
      retryCount: 3
    }
  );

  return data;
};

// Получение информации о Директоре из МИН ЮСТ
export const fetchDirectorInfo = async (
  id: ApplicationId
): Promise<IResponseData<Director>> => {
  const { data } = await api.get(
    `/api/onboarding/director-info/${id}`
  );

  return data;
};

// Получение cписка городов
export const fetchCityList = async (
  apiVersion: ApiVersions = ApiVersions.v2
): Promise<IResponseData<City>> => {
  const { data } = await api.get(
    '/api/onboarding/cities',
    { apiVersion }
  );

  return data;
};

// Получение cписка филиалов
export const fetchBankBranches = async (
  requestData: BankBranchesRequest
): Promise<IResponseData<BankMfo>> => {
  const { data } = await api.get(
    '/api/onboarding/branches',
    {
      params: requestData
    }
  );

  return data;
};

// Получение рабочих дней
export const fetchBusinessDays = async (
  branch: string | number
): Promise<IResponseData<BusinessDays>> => {
  const { data } = await api.get(
    `/api/onboarding/${branch}/business-days`
  );

  return data;
};

// Получение не рабочих дней
export const fetchNonBusinessDays = async (): Promise<IResponseData<string>> => {
  const { data } = await api.get(
    '/api/onboarding/non-business-days'
  );

  return data;
};

// Метод для обозначение что заявка больше не новая
export const markOldApplication = async (
  id: ApplicationId
): Promise<IResponseData<boolean>> => {
  const { data } = await api.put(`/api/onboarding/mark-old/${id}`);

  return data;
};

// Валидация ссылки для продолжения процесса
export const validateAuthLink = async (
  requestData: ValidateAuthLink
): Promise<IResponseData<OnboardingSignIn>> => {

  const { data } = await api.post(
    '/api/onboarding/validate-link-auth', null,
    {
      params: requestData,
      optionalAuth: true,
      hideErrorMessage: true
    }
  );

  return data;
};

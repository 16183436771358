import {
  Component,
  computed,
  defineAsyncComponent
} from 'vue';
import { IProps } from './base-icon.types';

export default function useBaseIcon(props: IProps) {
  const isSlot = computed<boolean>(() => typeof props.icon !== 'string');

  const SVGComponent = computed<Component>(() => props.icon && defineAsyncComponent(
    () => import(`../../icons/${props.icon}.svg`)
  ));

  return {
    isSlot,
    SVGComponent
  };
}

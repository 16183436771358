import { CurrencyAlphaCodeNumber } from './currency.types';

export enum PaymentCardStatuses {
  Deleted = -5,
  New = 0,
  Active,
  NotReceived,
  Blocked,
  Closed,
  Inactive
}

export interface PaymentCardsParams {
  states: PaymentCardStatuses | PaymentCardStatuses[];
  currencyIds?: CurrencyAlphaCodeNumber[]
}
